<template>
  <div>
    <AnnouncementTypesFilter
      :csv-filter-options="displayedFilterOptions"
      :already-selected-displayed-columns="filtersData?.displayedColumns"
      @apply="applyFilters"
    />
    <div v-if="isLoading" class="mt-28">
      <Loader :content="true" />
    </div>
    <div v-else-if="!isLoading && isEmpty(announcementTypesList)">
      <NoRecordFound />
    </div>
    <div v-else class="bg-white md:mx-0 mx-5 mt-5 relative rounded-lg border border-primary-grey">
      <div
        id="table-container"
        :class="[leftBlurArea, rightBlurArea]"
        class="overflow-x-auto rounded-lg"
        @scroll.passive="scrollDetect"
      >
        <table class="w-full table-auto whitespace-nowrap">
          <thead class="bg-bright-gray">
            <tr class="text-left">
              <th
                v-for="head in tableHeaders.filter((head) => !head.skipForDataTableHead)"
                v-show="
                  showColumnInDataTable(filtersData, head.dataTableName, ['TITLE', 'DESCRIPTION'])
                "
                :key="head.id"
                class="font-roboto font-medium text-base text-text-color py-5 rtl:text-right rtl:pr-4 rtl:pl-0 ltr:pl-4 ltr:pr-0"
              >
                <span
                  class="rtl:border-l ltr:border-r border-primary-grey w-full block rtl:pl-3 ltr:pr-3"
                  :class="head.id === tableHeaders.length - 2 ? 'text-center' : ''"
                >
                  {{ $t(`dashboard.${head.dataTableName}`) }}
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(type, index) in announcementTypesList"
              :key="index"
              class="border-t border-primary-grey text-left"
            >
              <td
                v-show="showColumnInDataTable(filtersData, 'TITLE', ['TITLE'])"
                class="ltr:border-r rtl:border-l border-primary-grey py-5 rtl:text-right rtl:pr-4 ltr:px-4"
              >
                <span class="font-roboto font-normal text-text-color text-sm">
                  {{ type.title || GENERAL_CONSTANTS.NOT_APPLICABLE }}
                </span>
              </td>
              <td
                v-show="showColumnInDataTable(filtersData, 'DESCRIPTION', ['DESCRIPTION'])"
                class="ltr:border-r rtl:border-l border-primary-grey py-5 rtl:text-right rtl:pr-4 ltr:px-4"
              >
                <span class="font-roboto font-normal text-text-color text-sm">
                  {{ type.description || GENERAL_CONSTANTS.NOT_APPLICABLE }}
                </span>
              </td>
              <td
                v-show="showColumnInDataTable(filtersData, 'ACTIONS')"
                class="border-primary-grey w-10 px-5"
              >
                <TableAction
                  :idx="index"
                  :current-user="type"
                  :action-list="actionList"
                  :top="dropdownTopBottom(index, announcementTypesList, -45, -12)"
                  :drop-down="
                    dropdownTopBottom(
                      index,
                      announcementTypesList,
                      'tableDropDownbottom',
                      'tableDropDowntop',
                    )
                  "
                  :right="50"
                  :rtl-right="-245"
                  @action="typeAction"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <Pagination
      v-if="announcementTypesCount > 9"
      v-show="!isLoading"
      :key="`ForceRender${forceRender}`"
      :record-limit="filteredRecordLimit"
      :max-range="paginationCounts(announcementTypesCount, filteredRecordLimit)"
      @filterRecord="getAnnouncementList"
    />
    <ValidationObserver @submit="addAndEditAnnouncementType">
      <UiModalContainer
        footer-classes="flex justify-end gap-3"
        :modal-show="showModal"
        :modal-width="50"
        @handleClick="handleClick"
      >
        <template v-slot:header>
          <span v-if="!updateTypeButton">Add Announcement Type</span>
          <span v-else>Edit Announcement Type</span>
        </template>
        <template v-slot>
          <div>
            <InputFieldWrapper>
              <InputBox
                v-model.trim="announementData.title"
                type="text"
                name="Title"
                title="Title"
                label="Title"
                placeholder="Write"
                :class="$style.widthItems"
                class="flex-1"
                rules="required|max:255"
              />
              <InputBox
                v-model="announementData.description"
                type="text"
                name="Description"
                title="Description"
                label="Description"
                placeholder="Description"
                :class="$style.widthItems"
                class="flex-1"
                rules="max:255"
              />
            </InputFieldWrapper>
          </div>
        </template>
        <template v-slot:footer>
          <div class="flex gap-3 rtl:gap-x-4">
            <UIButton :disabled="isLoading" @click="handleClick('close')">Cancel</UIButton>
            <UIButton button="primary" @click="assignSubjectHandler">
              <span v-if="isLoading">
                <Loader />
              </span>
              <span v-else>
                {{ primaryButtonText }}
              </span>
            </UIButton>
          </div>
        </template>
      </UiModalContainer>
    </ValidationObserver>
    <UIConfirmationModal
      v-if="confirmation"
      heading="DELETE_ANNOUNCEMENT_TYPE"
      message="Are you sure you want to delete "
      button-text="Delete"
      :modal="confirmation"
      :is-loading="isDeleteInProgress"
      :name="currentAnnouncementType.title"
      remaining-message="Announcement Type ?"
      @cancel="toggleConfirmationModal"
      @confirm="deleteAnnouncementTypeById"
    />
  </div>
</template>

<script>
import InputBox from '@components/UiElements/UiInputBox.vue'
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import AnnouncementTypesFilter from '@src/router/views/occasions/AnnouncementTypesFilter.vue'
import { mapActions, mapState } from 'vuex'
import TableAction from '@components/TableAction.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import { paginationCounts } from '@src/components/BaseComponent/pagination.util.js'
import {
  dropdownTopBottom,
  objectDeepCopy,
  showColumnInDataTable,
} from '@/src/utils/generalUtil.js'
import fileMixins from '@/src/mixins/file-mixins'
import Pagination from '@components/BaseComponent/Pagination.vue'
import GENERAL_CONSTANTS from '@src/constants/general-constants'
import scrollMixin from '@src/mixins/scroll-mixin'
import isEmpty from 'lodash/isEmpty'
import NoRecordFound from '@components/BaseComponent/NoRecordFound.vue'
import { Form as ValidationObserver } from 'vee-validate'
import UIConfirmationModal from '@src/components/UiElements/UIConfirmationModal.vue'
import UIButton from '@src/components/UiElements/UIButton.vue'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'

export default {
  name: 'AnnouncementsTypes',
  components: {
    UIConfirmationModal,
    UiModalContainer,
    AnnouncementTypesFilter,
    InputBox,
    TableAction,
    ValidationObserver,
    Loader,
    Pagination,
    NoRecordFound,
    UIButton,
    InputFieldWrapper,
  },
  mixins: [fileMixins, scrollMixin],
  data() {
    return {
      fees: 'fees',
      exams: 'exams',
      dashboard: 'dashboard',
      confirmation: false,
      announcementTypesList: [],
      updateTypeButton: false,
      modalButtonLoading: false,
      currentSelectedType: '',
      actionList: [{ name: 'Edit' }, { name: 'Delete' }],
      isDeleteInProgress: false,
      tableHeaders: [
        {
          id: '0',
          dataTableName: 'TITLE',
          backendKeyForCSV: 'TITLE',
          readableValueForCSV: 'TITLE',
          skipForFilterOptions: true,
        },

        {
          id: '1',
          dataTableName: 'DESCRIPTION',
          backendKeyForCSV: 'description',
          readableValueForCSV: 'Description',
          skipForFilterOptions: true,
        },

        {
          id: '2',
          dataTableName: 'ACTIONS',
          backendKeyForCSV: 'actions',
          readableValueForCSV: 'Actions',
        },
      ],
      defaultSelectedColumns: ['TITLE', 'DESCRIPTION', 'ACTIONS'],
      announementData: {
        id: '',
        title: '',
        description: '',
        is_active: true,
      },
      announcementTypesCount: 0,
      filteredRecordLimit: 10,
      forceRender: 0,
      filtersData: {},
      currentAnnouncementType: {},
      GENERAL_CONSTANTS,
    }
  },
  computed: {
    isCampusOrSectionChange() {
      return this.currentCampusScope + this.currentSectionScope
    },
    primaryButtonText() {
      return !this.updateTypeButton ? 'Save' : 'Update'
    },
    ...mapState({
      showModal: (state) => state.layout.showModal,
      isLoading: (state) => state.layout.isLoading,
      currentCampusScope: (state) => state.layout.currentCampusScope,
      currentSectionScope: (state) => state.layout.currentSectionScope,
      tabRightBar: (state) => state.layout.tabRightNav,
      activeRole: (state) => state.layout.activeRole,
    }),
  },
  watch: {
    isCampusOrSectionChange: {
      handler() {
        this.getAnnouncementList()
        this.setRightbarData()
      },
    },
    tabRightBar: {
      immediate: true,
      handler(value) {
        if (value) this.setRightbarData(this.announcementTypesCount)
      },
    },
  },
  /**
   * Mounted hook
   * @description Setting static right bar data
   */
  mounted() {
    this.getAnnouncementList()
    this.setRightbarData()
  },
  methods: {
    dropdownTopBottom,
    isEmpty,
    paginationCounts,
    showColumnInDataTable,

    deleteAnnouncementTypeById() {
      this.deleteAnnnouncementType(this.currentAnnouncementType.id)
    },
    applyFilters(filtersPayload) {
      this.filtersData = objectDeepCopy(filtersPayload)
      this.getAnnouncementList()
    },
    async getAnnouncementList(range) {
      const paginationLimit = {
        skip: range?.skip || this.GENERAL_CONSTANTS.RECORD_SKIP,
        limit: range?.limit || this.GENERAL_CONSTANTS.RECORD_LIMIT,
      }
      let payload = {
        ...paginationLimit,
      }
      payload = this.filtersData ? { ...payload, ...{ $where: this.filtersData?.$where } } : payload
      const [res, err] = await this.getAnnouncementTypes(payload)
      if (!err) {
        this.announcementTypesList = res?.data?.records
        this.announcementTypesCount = res?.data?.meta?.total_records
      }
      this.setRightbarData(this.announcementTypesCount)
    },
    /**
     * Handle click
     * @param {string} eventName
     * @returns {void}
     * @description Calling setShowModal and resetForm method on the basis of event name
     */
    handleClick(eventName) {
      switch (eventName) {
        case 'showModalOpen':
          this.setShowModal(true)
          break
        case 'close':
          this.setShowModal(false)
          this.resetForm()
          this.updateTypeButton = false
          break
        case 'confirm':
          break
      }
    },
    /**
     * Type Action
     * @param {string} eventName
     * @param {object} currentType
     * @param {number} idx
     * @returns {void}
     * @description Edit or delete current type on the basis of action
     */
    typeAction(action, currentType, idx) {
      switch (action) {
        case 'Edit':
          this.editType(currentType)
          break
        case 'Delete':
          this.currentAnnouncementType = currentType
          this.toggleConfirmationModal()
          break
      }
    },
    /**
     * Add Fee Types
     * @param {boolean} invalid
     * @returns {void}
     * @description Method description
     * -  Edit existing fee type
     * -  Show toast regarding to action we perform eg:Create or Edit
     * -  Setting right bar data
     */
    async addAndEditAnnouncementType() {
      if (this.updateTypeButton) {
        await this.updateAnnouncementType(this.announementData).then((response) => {
          this.getAnnouncementList()
          this.handleClick('close')
        })
      } else {
        await this.addAnnouncementType(this.announementData).then((response) => {
          this.getAnnouncementList()
          this.handleClick('close')
        })
      }
      this.forceRender++
    },
    /**
     * Reset Form
     * @description Resetting fee type name and description to empty value
     */
    resetForm() {
      this.announementData.title = ''
      this.announementData.description = ''
    },
    /**
     * Edit Type
     * @param {String} currentType
     * @returns {void}
     * @description Editing the type of fee on the basis of current type
     */
    editType(currentType) {
      this.$store.dispatch('layout/setShowModal', true)
      this.updateTypeButton = true
      this.announementData = objectDeepCopy(currentType)
      this.currentSelectedType = currentType
    },
    /**
     * Delete Type
     * @param {number} id - Current id of fee
     * @returns {void}
     * @description Deleting the fee on the basis of id
     */
    async deleteAnnnouncementType(id) {
      this.isDeleteInProgress = true
      await this.deletetAnnouncementType(id).then((response) => {
        this.toggleConfirmationModal()
        this.getAnnouncementList()
      })
      this.isDeleteInProgress = false
    },

    setRightbarData(count = 0) {
      const rightBarContent = {
        header: {
          title: 'Announcement Types',
          buttons: [
            {
              title: 'ADD_A_TYPES',
              classes: ['primary-button-right'],
              action: { name: 'layout/setShowModal', payload: {} },
            },
          ],
        },
        stats: [
          {
            title: GENERAL_CONSTANTS.STATS_AND_SUMMARY,
            categories: [{ text: 'TYPES', value: count }],
          },
        ],
      }
      this.setRightbarContent(rightBarContent)
    },

    toggleConfirmationModal() {
      this.confirmation = !this.confirmation
    },

    ...mapActions('layout', ['setShowModal', 'setRightbarContent']),
    ...mapActions('announcemettypes', [
      'getAnnouncementTypes',
      'addAnnouncementType',
      'updateAnnouncementType',
      'deletetAnnouncementType',
    ]),
  },
}
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}
.minHieght80 {
  min-height: 80vh;
}
.paid {
  padding: 4px 12px;
  color: #267c26;
  background-color: #e9f2e9;
  border-radius: 3px;
}
.delay {
  padding: 4px 12px;
  color: #ffc201;
  background-color: #fff9e6;
  border-radius: 3px;
}
.due {
  padding: 4px 12px;
  color: #ea3535;
  background-color: #fce1e1;
  border-radius: 3px;
}
tr:nth-child(even) {
  background-color: var(--ghost-white);
}
.tableDropDown {
  top: 32px;
  right: -21px;
  &::before {
    position: absolute;
    top: -7px;
    right: 30px;
    width: 12px;
    height: 12px;
    content: '';
    background: var(--bg-white);
    border-top: 1px solid var(--primary-grey);
    border-left: 1px solid var(--primary-grey);
    transform: rotate(45deg);
  }
}
.info {
  top: 35px;
  border: 1px solid var(--text-color-info);
  &::before {
    position: absolute;
    top: -7px;
    right: 30px;
    width: 12px;
    height: 12px;
    content: '';
    background: var(--bg-white);
    border-top: 1px solid var(--text-color-info);
    border-left: 1px solid var(--text-color-info);
    transform: rotate(45deg);
  }
}
</style>
